import type { Plugin } from "vue";
import { List, Image, Toast, TextEllipsis, Lazyload, BackTop, Empty, Loading } from "vant";

export default {
  install(Vue) {
    Vue.use(List)
      .use(Image)
      .use(Toast)
      .use(TextEllipsis)
      .use(Lazyload, { lazyComponent: true })
      .use(BackTop)
      .use(Empty)
      .use(Loading);
  }
} as Plugin;
