export const CategoryColors = [
  { color: "#EF2139", bgColor: "#FFE9E9" },
  { color: "#4A89E8", bgColor: "#D6E6FF" },
  { color: "#8B79F9", bgColor: "#E8E4FF" },
  { color: "#0CC773", bgColor: "#E0FFF1" },
  { color: "#EC8050", bgColor: "#FFEEE7" },

  { color: "#FF7C00", bgColor: "#FDDCC5" },
  { color: "#2793ff", bgColor: "#EFF5FB" },
  { color: "#c00", bgColor: "#F7EFF4" },
  { color: "#46c5cc", bgColor: "#dbfdff" },
  { color: "#b8ae00", bgColor: "#FEFBC3" },
  { color: "#1bd1d4", bgColor: "#c7feff" }
];

const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6936092', space_js: '//cwflhpb.chenglsw.com/site/n/ihqa/static/ho/q_x.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936095', space_js: '//cwflhpb.chenglsw.com/source/q_lkt_dkrt_i.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936094', space_js: '//cwflhpb.chenglsw.com/site/p/kj/production/s-cjqsg.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936096 ', space_js: '//cwflhpb.chenglsw.com/common/rm/luel_s/production/u_l.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936097', space_js: '//cwflhpb.chenglsw.com/common/snm/resource/v/source/f/openjs/mtv/o.js' }];


export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};